import * as Joi from 'joi';
export const COLUMNS = [
  {
    id: 'value.id',
    value: {
      plural: 'Layer'
    }
  },
  {
    id: 'value.stack.id',
    value: {
      plural: 'Stack'
    }
  }
];

export const DEV_ACCOUNT = 'Dev Account';
export const STG_ACCOUNT = 'Stg Account';
export const PROD_ACCOUNT = 'Prod Account';
export const OPS_ACCOUNT = 'Ops Account';

export const ROWS_PER_PAGE = 'Rows per page';
export const FROM_VAL = 'from';
export const SELECT_ALL = 'Select All';
