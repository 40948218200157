import * as React from 'react';
import RowInfoComponent from '../../common-components/row-info-component';
import { DEV_ACCOUNT, PROD_ACCOUNT, STG_ACCOUNT, OPS_ACCOUNT } from '../../constants';
import { Layer } from './view-all-table';
import TitleComponent from '../../common-components/title-component';

export const LayerExpandableComponent = ({ layer }: { layer: Layer }) => {

  const cleanedUrl = layer.value.jenkins.private_url
    ? layer.value.jenkins.private_url.replace(/https?:\/\//, '').replace(/\//g, '')
    : '';

  const fields = [
    { name: DEV_ACCOUNT, value: layer.value.dev.id },
    { name: STG_ACCOUNT, value: layer.value.stg.id },
    { name: PROD_ACCOUNT, value: layer.value.pro.id },
    { name: OPS_ACCOUNT, value: layer.value.jenkins.account.id }
  ];

  return (
    <div className={'container'} key={`${layer.value.id}-expandable`}>
      <span className={'container my-3'}>
        <div className="mx-3 flex-auto">
          <TitleComponent value="Jenkins Domain" size={'xs'} />
          <div className={'text-xs flex justify-center text-gray-600'}>
            <a href={layer.value.jenkins.private_url} target="_blank">
              {cleanedUrl}
            </a>
          </div>
        </div>
      </span>
      {fields.map(({ name, value }) => (
        <RowInfoComponent key={name} tittle={name} value={value} />
      ))}
    </div>
  );
};

export default React.memo(LayerExpandableComponent);
