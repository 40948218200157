import * as React from 'react';
import '../tailwind.scss';
export default function TitleComponent(props: TitleStyleProps): JSX.Element {
  return <h3 className={`flex justify-center text-${props.size} font-bold text-center text-black`}>{props.value}</h3>;
}

export interface TitleStyleProps {
  value: string;
  size: string; // Sizes can be l, m, s => large medium small
}
