import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import DataTable, { createTheme, ExpanderComponentProps } from 'react-data-table-component';
import { useMutation } from 'react-query';
import FilterComponent from '../../common-components/filter-componet';
import { useStateCallback } from '../../hooks/use-state-callback';
import { layersGetAllService } from '../services/layers-get-all-service';
import '../../tailwind.scss';
import { FROM_VAL, ROWS_PER_PAGE, SELECT_ALL } from '../../constants';
import { LayerExpandableComponent } from './layer-expandable-component';
import { VuCustomLoader } from '@avaldigitallabs/adl-rockstars-design-system-vulcanus';

export default function ViewAllTable(props: ViewAllProps): JSX.Element {
  const [selectedKey, setKey] = useState('');
  const [selectedValue, setValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState(selectedValue);
  const [perPage, setPerPage] = useStateCallback(10);
  const [start, setStart] = useState(true);
  const [dataState, setDataState] = useStateCallback([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = useMemo(() => {
    console.log;
    if (dataState) {
      return dataState;
    } else {
      return [];
    }
  }, [dataState, filterText]);

  const layerQueryAll = useMutation(layersGetAllService, {
    onSuccess: data => {
      try {
        setDataState(data.data?.getPagHefestoLayerConstants.data);
        setLoading(false);
        setTotalRows(data.data?.getPagHefestoLayerConstants.totalItems);
      } catch (error) {
        setDataState([]);
        setTotalRows(0);
        setLoading(false);
      }
    },
    onError: (e: string) => {
      console.log({
        title: 'Error',
        message: e,
        active: true
      });
    }
  });
  if (start) {
    layerQueryAll.mutate({
      page: 1,
      limit: 10,
      auth: props.ogmioToken.toString(),
      filter: {
        key: '',
        value: ''
      }
    });
    setStart(false);
  }

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLoading(true);
    setPerPage(newPerPage);
    layerQueryAll.mutate({
      page,
      limit: newPerPage,
      auth: props.ogmioToken,
      filter: {
        key: '',
        value: ''
      }
    });
  };

  const columns = [
    {
      name: 'ID Layer',
      selector: (layer: Layer) => layer.id,
      sortable: true,
      reorder: true
    },
    {
      name: 'Layer',
      selector: (layer: Layer) => layer.value.id,
      sortable: true,
      reorder: true
    },
    {
      name: 'Stack',
      selector: (layer: Layer) => layer.value.stack.id,
      sortable: true,
      reorder: true
    }
  ];

  createTheme('ligths', {
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)'
    },
    background: {
      default: '#FFFFFF'
    },
    context: {
      background: '#e3f2fd',
      text: 'rgba(0, 0, 0, 0.87)'
    },
    divider: {
      default: 'rgba(0,0,0,.12)'
    },
    button: {
      default: 'rgba(0,0,0,.54)',
      focus: 'rgba(0,0,0,.12)',
      hover: 'rgba(0,0,0,.12)',
      disabled: 'rgba(0, 0, 0, .18)'
    },
    selected: {
      default: '#e3f2fd',
      text: 'rgba(0, 0, 0, 0.87)'
    },
    highlightOnHover: {
      default: '#EEEEEE',
      text: 'rgba(0, 0, 0, 0.87)'
    },
    striped: {
      default: '#FAFAFA',
      text: 'rgba(0, 0, 0, 0.87)'
    }
  });
  createTheme('darks', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198'
    },
    background: {
      default: '#002b36'
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF'
    },
    divider: {
      default: '#073642'
    },
    button: {
      default: '#2aa198',
      hover: 'rgba(0,0,0,.08)',
      focus: 'rgba(255,255,255,.12)',
      disabled: 'rgba(255, 255, 255, .34)'
    },
    sortFocus: {
      default: '#2aa198'
    }
  });

  const handlePageChange = async (page: number) => {
    setLoading(true);
    layerQueryAll.mutate({
      page,
      limit: perPage,
      auth: props.ogmioToken,
      filter: {
        key: '',
        value: ''
      }
    });
  };
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(selectedValue), 2000);
    return () => clearTimeout(timer);
  }, [selectedValue]);

  useEffect(() => {
    layerQueryAll.mutate({
      page: 1,
      limit: 10,
      auth: props.ogmioToken.toString(),
      filter: {
        key: selectedKey,
        value: debouncedValue
      }
    });
  }, [debouncedValue]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const handleSubmit = (key: string, value: string) => {
      setLoading(true);
      setKey(key);
      if (value == '') {
        setDebouncedValue(value);
      } else {
        setValue(value);
      }
    };

    return (
      <FilterComponent
        onFilter={(e: React.FormEvent<HTMLInputElement>) => {
          setFilterText(e.currentTarget.value);
        }}
        onClear={handleClear}
        filterText={filterText}
        dataState={dataState}
        setDataState={setDataState}
        onSubmit={handleSubmit}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="flex items-center justify-center h-screen w-screen pt-16">
      <div className="h-screen w-11/12">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">{subHeaderComponentMemo}</div>
        </div>
        <div className="mt-8 flow-root bg-white rounded-xl">
          <div className="m-8">
            <DataTable
              columns={columns}
              data={filteredItems}
              highlightOnHover
              progressPending={loading}
              progressComponent={<VuCustomLoader height={'200'} width={'200'} color={'vulcanus_delft_blue'} />}
              defaultSortFieldId={1}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationComponentOptions={paginationOptions}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              expandableRows={true}
              expandOnRowClicked={true}
              expandableRowsComponent={(layer: ExpanderComponentProps<Layer>) => (
                <LayerExpandableComponent layer={layer.data} />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export interface Layer {
  id: string;
  value: {
    id: string;
    stack: {
      id: string;
    };
    dev: {
      id: string;
      label: string;
    };
    stg: {
      id: string;
      label: string;
    };
    pro: {
      id: string;
      label: string;
    };
    jenkins: {
      domain: string;
      private_url: string
      id: string;
      account: {
        id: string;
        label: string;
        env: {
          id: string;
          type: string;
        };
      };
    };
    role: Role[];
  };
}

export interface Role {
  singular: string;
  plural: string;
}

export interface ViewAllProps {
  ogmioToken: string;
}

const paginationOptions = {
  rowsPerPageText: ROWS_PER_PAGE,
  rangeSeparatorText: FROM_VAL,
  selectAllRowsItem: true,
  selectAllRowsItemText: SELECT_ALL
};
