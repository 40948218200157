import * as React from 'react';
import '../tailwind.scss';
import TitleComponent from './title-component';

export default function RowInfoComponent(props: BulbProps): JSX.Element {
  return (
    <div className="mx-3 flex-auto">
      <TitleComponent value={props.tittle} size={'xs'} />
      <div className={'text-xs flex justify-center text-gray-600'}>{props.value}</div>
    </div>
  );
}

export interface BulbProps {
  tittle: string;
  value: string;
}
