import axios from 'axios';
import { getPagHefestoLayerConstants } from '../graphql/queries';

export const layersGetAllService = async (layersGetAllProps: LayersGetAllProps) => {
  const config = {
    headers: { Authorization: layersGetAllProps.auth, 'Content-Type': 'application/graphql' }
  };
  const graphql = JSON.stringify({
    query: getPagHefestoLayerConstants,
    variables: {
      input: {
        limit: layersGetAllProps.limit,
        page: layersGetAllProps.page,
        filter: layersGetAllProps.filter
      }
    }
  });

  const ULR = process.env.GRAPHQLURL !== undefined ? process.env.GRAPHQLURL : '';
  const { data: response } = await axios.post(ULR, graphql, config);
  return response;
};

export interface LayersGetAllProps {
  auth: string;
  page: number;
  limit: number;
  filter: {
    key: string;
    value: string;
  };
}
